<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "map-view",
  props: {
    mapData: {
      //中心点
      type: Object,
      required: false,
      default: ()=>{},
    },
  },
  data() {
    return {
      geojson: null,
    };
  },
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "6d55f7be376408a84f88c266ce17c7ee",
      };
      AMapLoader.reset();
      AMapLoader.load({
        key: "462c641e637730356419311563662320",
        version: "2.0",
        plugins: [
          "AMap.Scale",
          "AMap.MouseTool",
          "AMap.Marker",
          "AMap.ContextMenu",
          "AMap.Polygon",
          "AMap.GeoJSON",
        ],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            viewMode: "3D", // 是否为3D地图模式
            zoom: this.mapData.zoom||11, // 初始化地图级别
            layers: [new AMap.TileLayer.Satellite()],
            center: this.mapData.centerPosition.split(","), // 初始化地图中心点位置 106.865155,29.448244
          });
          this.drawJson();
        })
        .catch((e) => {
          //console.log(e);
        });
    },
    async drawJson() {
        // 设置地图zoom值
      if (this.mapData.zoom) this.map.setZoom(this.mapData.zoom)
      if (this.mapData.polygonWkt) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(this.mapData.polygonWkt), // 设置多边形坐标数组
          strokeColor: "#0bc0b8", // 线条颜色
          strokeWeight: 4, //线条宽度
          fillColor: "#000", // 填充颜色
          fillOpacity: 0.2, // 填充透明度
        });
        polygon.setMap(this.map);
         // 绘制标记
         /*const lat = this.mapData.centerPosition.split(",");
            const marker = new AMap.Marker({
                position: new AMap.LngLat(lat[0], lat[1]),
                offset: new AMap.Pixel(-10, -10),
                icon: require("../../../assets/aggroTrace/crop.png"),
                zooms: [5, 18],
            });
            //console.log('marker',marker)
            this.map.add(marker);*/
      } 
      if (this.mapData.geoJson) {
        await fetch(`/geojson/${this.mapData.geoJson}.json`)
          .then((response) => response.json())
          .then(async (data) => {
            let dataGeo = [];
            await new AMap.GeoJSON({
              geoJSON: data,
              getPolygon: function (geojson, lnglats) {
                dataGeo = lnglats[0];
              },
            });
            var polygon = new AMap.Polygon({
              path: dataGeo, // 设置多边形坐标数组
              strokeColor: "yellow", // 线条颜色
              strokeWeight: 4, //线条宽度
              fillColor: "red", // 填充颜色
              fillOpacity: 0.1, // 填充透明度
            });
            polygon.setMap(this.map);
           
          })
          .catch((error) => {
            console.error("Error loading data:", error);
          });
        // 创建纯文本标记
        const lat = this.mapData.centerPosition.split(",");
        var text = new AMap.Text({
          text:this.mapData.mapName,
          anchor:'center', // 设置文本标记锚点
          draggable:false,
          cursor:'pointer',
          angle:0,
          style:{
            'padding': '.75rem 1.25rem',
            'margin-bottom': '1rem',
            'border-radius': '.25rem',
            'background-color': 'rgba(0,0,0,0)',
            'width': '15rem',
            'border-width': 0,
            'text-align': 'center',
            'font-size': '14px',
            'color': 'yellow',
            'font-weight':'bold',
          },
          position: new AMap.LngLat(lat[0], lat[1]),
        });

        text.setMap(this.map);

      }
    },
  },
};
</script>
<style scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
}
</style>
